import axios from 'axios'
import md5 from 'js-md5'
//const ApiURL = "/api"
const ApiURL = "https://dm.jinrui.cc/v2"

function sortObj(obj) {
    var keysArr = Object.keys(obj).sort();
    var sortObj = {};
    for (var i in keysArr) { sortObj[keysArr[i]] = obj[keysArr[i]]; }
    return sortObj;
}
// 加密数据
function md5Data(data) {
    let keyStr = ''
    // 排序
    data = sortObj(data)
    for (var key in data) {
        keyStr += `${key}=${data[key]}&`
    }
    keyStr += `key=${'LBDANDJINRUIDM'}`
    return md5(keyStr).toUpperCase()
}
const http = ({method, url, data, ContentType}) => {
    let par = {
        method: method,
        url: ApiURL + url,
    }
	
	let timestamp = Math.round(new Date() / 1000)
	let newData = sortObj({ ...data, timestamp })
	
    if (method == 'post') {
        par['data'] = {
			...newData,
			sign: md5Data(JSON.parse(JSON.stringify(newData)))
		}
    }
	
    if (true) {
        par['headers'] = {
            ...par['headers'],
            'token': localStorage.getItem("token")
        }
    }
	
	if (ContentType) {
		par['headers'] = {
		    ...par['headers'],
		    ...ContentType
		}
	}
	
	if (data.isFile) {
		let timestamp = Math.round(new Date() / 1000)
		let formData = new FormData()
		formData.append('file', data.file)
		formData.append('timestamp', timestamp)
		formData.append('sign', md5Data(JSON.parse(JSON.stringify({timestamp: timestamp}))))
		par['data'] = formData
	}

    if (method == 'get') {
        par['params'] = data
    }
    return axios(par).then((res) => {
        if (res.data.code == 201) {
			if (window.location.href.indexOf('#/login') >= 0) {
			} else {
				window.location.href = '#/login'
				return false
			}
        }
		return res.data
	})
}

export default http